<template>
  <div class="container py-5 px-0 animate__animated animate__fadeIn">
    <h3 class="mb-4 text-center">Iniciar Sesión</h3>
    <p :class="noUsernameDisplay" class="text-center text-danger">No se ha introducido un usuario.</p>
    <p :class="noUsernameValidDisplay" class="text-center text-danger">Usuario no válido.</p> 
    <input type="text" v-model="username" ref="username" v-on:keyup.enter="nextStep()" class="form-control mb-3" placeholder="Usuario">
    <button class="btn btn-primary btn-block mb-3" v-on:click="nextStep()">Siguiente</button>
  </div>
</template>

<script>
import axios from 'axios'
import current from '@/logic/settings.js'

export default {
  name: 'LoginUsername',
  data() {
    return {
      username: '',
      noUsernameDisplay: '',
      noUsernameValidDisplay: ''
    }
  },
  methods: {
    nextStep() {
      console.log("Username:" + this.username)
      if(typeof(this.username) == 'undefined') this.noUsernameDisplay = "d-block"
      else {
        
        var options = []
        var headers = {
          'Access-Control-Allow-Origin' : '*',
          'Access-Control-Allow-Methods':'GET',
          "Access-Control-Allow-Headers": "Origin, Content-Type",
          "Content-Type": "application/json",
        }
        var data = { username: this.username }

        axios.post(current.api().endpoint + current.api().version + '/username', data, { headers }).then( (result) => {

          options = result.data.data
          
          if(options.length == 1) {
            this.$store.commit("setLogin", { username: this.username })
            this.$store.commit("setCurrentStep", "clogin" + options[0].name)  
          } else {
            this.noUsernameValidDisplay = 'd-block'
          }
        })
      }
    },
    focusInput() {
      this.$refs.username.focus();
    }
  },
  mounted() {
    this.$store.commit("setCurrentStep", "cloginusername")
    this.username = this.$store.getters.getLogin.username
    this.noUsernameDisplay = "d-none"
    this.noUsernameValidDisplay = "d-none"
    this.focusInput()
  },
}
</script>